import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShare, faAngleRight, faMapMarkerAlt, faEnvelope, faPhone, faCopyright } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faTwitter, faInstagram, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import './Footer.css'
import { Link } from 'react-router-dom';
const Footer = () => {
    return (
        <div>
            <div className="container-fluid footer py-3 wow fadeIn" data-wow-delay="0.2s">
                <div className="container py-5">
                    <div className="row g-5">
                        <div className="col-md-6 col-lg-6 col-xl-3">
                            <div className="footer-item d-flex flex-column">
                                <h4 className="text-white mb-4">CORPWINGS</h4>
                                <p className="text-white">
                                    Welcome to Corpwings, where we transform your digital dreams into reality. With our website creation
                                    service, we craft tailored online platforms that captivate, engage, and convert.
                                </p>
                                
                                <div className="d-flex align-items-center">
                                    <FontAwesomeIcon icon={faShare} className="fa-2x text-white me-3" />
                                    <a
                                        className="btn-square btn btn-primary text-white rounded-circle d-flex justify-content-center align-items-center mx-1"
                                        href="https://www.facebook.com/profile.php?id=61551671053085&mibextid=JRoKGi"
                                        style={{ width: '40px', height: '40px' }} // Adjust the size as needed
                                    >
                                        <FontAwesomeIcon icon={faFacebookF} />
                                    </a>

                                    <a
                                        className="btn-square btn btn-primary text-white rounded-circle d-flex justify-content-center align-items-center mx-1"
                                        href="https://twitter.com/Corpwings_offi?t=jx8nPpPWYghfRuiRBeZw8w&s=08"
                                        style={{ width: '40px', height: '40px' }} // Adjust the size as needed
                                    >  <FontAwesomeIcon icon={faTwitter} />
                                    </a>
                                    <a
                                        className="btn-square btn btn-primary text-white rounded-circle d-flex justify-content-center align-items-center mx-1"
                                        href="https://www.instagram.com/corpwings_official/?igsh=NG1xYWZmd3Zsb2dp"
                                        style={{ width: '40px', height: '40px' }} // Adjust the size as needed
                                    >   <FontAwesomeIcon icon={faInstagram} />
                                    </a>
                                    <a
                                        className="btn-square btn btn-primary text-white rounded-circle d-flex justify-content-center align-items-center mx-1"
                                        href="https://www.linkedin.com/in/corp-wings-584097306/"
                                        style={{ width: '40px', height: '40px' }} // Adjust the size as needed
                                    >   <FontAwesomeIcon icon={faLinkedinIn} />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6 col-xl-3">
                            <div className="footer-item d-flex flex-column text-white">
                                <h4 className="mb-4 text-white">Quick Links</h4>
                                <Link to={`/about`} className="text-white d-flex align-items-center mb-2 text-decoration-none">
                                    <FontAwesomeIcon icon={faAngleRight} className="me-2" /> About Us
                                </Link>
                                <Link to={`/contactUs`}  className="text-white d-flex align-items-center mb-2">
                                    <FontAwesomeIcon icon={faAngleRight} className="me-2" /> Contact Us
                                </Link>
                                <Link to={`/blog`} className="text-white d-flex align-items-center mb-2">
                                    <FontAwesomeIcon icon={faAngleRight} className="me-2" /> Our Blog & News
                                </Link>
                                {/* <Link  className="text-white d-flex align-items-center mb-2">
                                    <FontAwesomeIcon icon={faAngleRight} className="me-2" /> Our Team
                                </Link> */}
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6 col-xl-3">
                            <div className="footer-item d-flex flex-column text-white">
                                <h4 className="mb-4 text-white">CorpWings Services</h4>
                                <Link to={`/services`} className="text-white d-flex align-items-center mb-2">
                                    <FontAwesomeIcon icon={faAngleRight} className="me-2" /> All Services
                                </Link>
                                <Link to={`/services/developing`} className="text-white d-flex align-items-center mb-2">
                                    <FontAwesomeIcon icon={faAngleRight} className="me-2" /> Developing
                                </Link>
                                <Link to={`/services/designing`} className="text-white d-flex align-items-center mb-2">
                                    <FontAwesomeIcon icon={faAngleRight} className="me-2" /> Designing
                                </Link>
                                <Link to={`/services/digitalMarketing`} className="text-white d-flex align-items-center mb-2">
                                    <FontAwesomeIcon icon={faAngleRight} className="me-2" /> Digital Marketing
                                </Link>
                                <Link to={`/services/branding`} className="text-white d-flex align-items-center mb-2">
                                    <FontAwesomeIcon icon={faAngleRight} className="me-2" /> Branding
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6 col-xl-3">
                            <div className="footer-item d-flex flex-column text-white">
                                <h4 className="mb-4 text-white">Contact Info</h4>
                                {/* <a target='_blank' rel="noopener noreferrer" href="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3888.405862533737!2d78.86948067484091!3d12.94586108736718!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTLCsDU2JzQ1LjEiTiA3OMKwNTInMTkuNCJF!5e0!3m2!1sen!2sin!4v1742290600171!5m2!1sen!2sin" className="text-white d-flex  mb-2"> */}
                                <a href="/" className="text-white d-flex  mb-2">
                                    <FontAwesomeIcon icon={faMapMarkerAlt} className="me-3 pt-2" /> Address: 22, Raja St, Teachers Colony, Pudupet, Ambapuram, Gudiyatham, Tamil Nadu 632602
                                </a>
                                <a href="mailto:corpwingsofficial@gmail.com" className="text-white d-flex align-items-center mb-2">
                                    <FontAwesomeIcon icon={faEnvelope} className="me-3" /> corpwingsofficial@gmail.com
                                </a>
                               <div className='d-flex align-items-center mb-2'>
                               <FontAwesomeIcon icon={faPhone} className="me-3" />
                               <a href="tel:6380341944" className="text-white d-flex align-items-center  me-4">
                                     6380341944
                                </a>
                                <a href="tel:6380944811" className="text-white d-flex align-items-center">
                                     6380944811
                                </a>
                               </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid copyright py-4">
                <div className="container">
                    <div className="row g-4 align-items-center">
                        <div className="col-md-6 text-center text-md-start mb-md-0">
                            <span className="text-white d-flex align-items-center">
                                <FontAwesomeIcon icon={faCopyright} className="text-light me-2" />
                                <Link to={`/`} className="text-white">corpwings</Link> , All right reserved.
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;
